// Generic constants
const lineWidth = 8
const lineOpacity = 0.45
const innerRadius = 4
const outerRadius = 8

// Polygon fill
const polygonFill = "hsl(179, 22%, 82%)"
const polygonFillOpacity = 0.55

// Polygon outline
const polygonOutlineInactive = "hsl(178, 41%, 40%)"
const polygonOutlineInactiveWidth = lineWidth
const polygonOutlineInactiveOpacity = lineOpacity

const polygonOutlineActive = "hsl(178, 41%, 29%)"
const polygonOutlineActiveWidth = lineWidth
const polygonOutlineActiveOpacity = lineOpacity

// Midpoint between verticies for editing
// lines and polygons.  Used for adding new vertices.
const midpointInner = "#ffffff"
const midpointInnerRadius = innerRadius

const midpointOuter = "hsl(178, 41%, 40%)"
const midpointOuterRadius = outerRadius

// Line
const lineActive = "hsl(178, 41%, 40%)"
const lineActiveWidth = lineWidth
const lineActiveOpacity = lineOpacity

const lineInactive = "hsl(178, 41%, 29%)"
const lineInactiveWidth = lineWidth
const lineInactiveOpacity = lineOpacity

// Vertices for selected polygons and lines
const vertexInner = "#ffffff"
const vertexInnerRadius = innerRadius

const vertexOuter = "hsl(178, 41%, 40%)"
const vertexOuterRadius = outerRadius

// Note for styles below
// Midpoint and Vertex are only drawn for active LineStrings and Polygons
// As a result, these styles do not need "== active" filter component

const pointOuterInactive = "#FFFFFF"
const pointOuterInactiveRadius = outerRadius

// Active points
const pointInner = "hsl(178, 41%, 40%)"
const pointInnerRadius = innerRadius * 4

const pointOuter = "#000000"

var operationsStyle = [
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": polygonFill,
      "fill-opacity": polygonFillOpacity
    }
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": polygonOutlineInactive,
      "line-width": polygonOutlineInactiveWidth,
      "line-opacity": polygonOutlineInactiveOpacity
    }
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": polygonOutlineActive,
      //"line-dasharray": [0.2, 2],
      "line-opacity": polygonOutlineActiveOpacity,
      "line-width": polygonOutlineActiveWidth
    }
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "false"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": lineInactive,
      "line-opacity": lineActiveOpacity,
      "line-width": lineInactiveWidth
    }
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": lineActive,
      //"line-dasharray": [0.2, 2],
      "line-opacity": lineActiveOpacity,
      "line-width": lineActiveWidth
    }
  },
  {
    id: "gl-draw-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": midpointInnerRadius,
      "circle-color": midpointInner,
      "circle-stroke-color": midpointOuter,
      "circle-stroke-width": 3
    }
  },
  {
    id: "gl-draw-vertex",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "vertex"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": vertexInnerRadius,
      "circle-color": vertexInner,
      "circle-stroke-color": vertexOuter,
      "circle-stroke-width": 3
    }
  },
  {
    id: "gl-draw-point-active",
    type: "symbol",
    filter: ["all", ["==", "$type", "Point"], ["==", "active", "true"], ["!=", "meta", "midpoint"], ["!=", "meta", "vertex"]],
    paint: {
      "icon-color": "hsl(0, 0, 0)",
      "icon-opacity": 1.0
      //'icon-halo-color': 'hsl(0, 100%, 100%)',
      //'icon-halo-width': 20,
      //'icon-halo-blur': 30
    },
    layout: {
      "icon-image": [
        "case",
        ["==", ["get", "user_type"], "ATM"],
        "map-atm-active",
        ["==", ["get", "user_type"], "Bar"],
        "map-bar-active",
        ["==", ["get", "user_type"], "Camera"],
        "map-camera-active",
        ["==", ["get", "user_type"], "Note"],
        "map-note-active",
        ["==", ["get", "user_type"], "Disability"],
        "map-disability-active",
        ["==", ["get", "user_type"], "Exit"],
        "map-exit-active",
        ["==", ["get", "user_type"], "Food"],
        "map-food-active",
        ["==", ["get", "user_type"], "Parking"],
        "map-parking-active",
        ["==", ["get", "user_type"], "Restroom"],
        "map-restroom-active",
        ["==", ["get", "user_type"], "Security"],
        "map-security-active",
        ["==", ["get", "user_type"], "Sponsor"],
        "map-sponsor-active",
        ["==", ["get", "user_type"], "Stage"],
        "map-stage-active",
        ["==", ["get", "user_type"], "Tickets"],
        "map-tickets-active",
        ["==", ["get", "user_type"], "Vendor"],
        "map-vendor-active",
        "map-generic-poi-active"
      ],
      "icon-size": ["interpolate", ["linear"], ["zoom"], 14, 0.3, 15, 0.5, 16, 0.8, 17, 1.0, 19, 1.2, 21, 1.4]
    }
  },
  {
    id: "gl-draw-point-inactive",
    type: "symbol",
    filter: ["all", ["==", "$type", "Point"], ["!=", "active", "true"], ["!=", "mode", "static"], ["!=", "meta", "midpoint"], ["!=", "meta", "vertex"]],
    paint: {
      "icon-color": "hsl(0, 0, 0)",
      "icon-opacity": 0.7,
      "icon-halo-color": "hsl(0, 100%, 100%)",
      "icon-halo-width": 6,
      "icon-halo-blur": 3
    },
    layout: {
      "icon-image": [
        "case",
        ["==", ["get", "user_type"], "ATM"],
        "map-atm",
        ["==", ["get", "user_type"], "Bar"],
        "map-bar",
        ["==", ["get", "user_type"], "Camera"],
        "map-camera",
        ["==", ["get", "user_type"], "Note"],
        "map-note",
        ["==", ["get", "user_type"], "Disability"],
        "map-disability",
        ["==", ["get", "user_type"], "Exit"],
        "map-exit",
        ["==", ["get", "user_type"], "Food"],
        "map-food",
        ["==", ["get", "user_type"], "Parking"],
        "map-parking",
        ["==", ["get", "user_type"], "Restroom"],
        "map-restroom",
        ["==", ["get", "user_type"], "Security"],
        "map-security",
        ["==", ["get", "user_type"], "Sponsor"],
        "map-sponsor",
        ["==", ["get", "user_type"], "Stage"],
        "map-stage",
        ["==", ["get", "user_type"], "Tickets"],
        "map-tickets",
        ["==", ["get", "user_type"], "Vendor"],
        "map-vendor",
        "map-generic-poi"
      ],
      "icon-size": ["interpolate", ["linear"], ["zoom"], 14, 0.3, 15, 0.5, 16, 0.8, 17, 1.0, 19, 1.2, 21, 1.4]
    }
  },
  // All these below are static.  Disregard for now as
  // we don't deal with static maps.
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#404040",
      "fill-outline-color": "#404040",
      "fill-opacity": 0.1
    }
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2
    }
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2
    }
  },
  {
    id: "gl-draw-point-static",
    type: "circle",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#404040"
    }
  }
]

function OperationsCustomStyle() {
  /*
    const found = operationsStyle.find(id => id == "gl-draw-point-static");

    console.log(JSON.stringify(found, null, 2));
    */

  return operationsStyle
}

export { OperationsCustomStyle }
