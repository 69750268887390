<template></template>

<script>
import { OperationsCustomStyle } from "@/utils/map-style.js";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
export default {
  name: "MapDraw",
  components: {},
  props: {
    map: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      draw: null,
    };
  },

  methods: {
    extend() {
      // This is a substitute for jQuery.extend, used in OperationsCustomPolygonMode
      // and OperationsCustomLineMode below.  Application no longer uses jQuery.
      // https://stackoverflow.com/questions/11197247/javascript-equivalent-of-jquerys-extend-method

      for (var i = 1; i < arguments.length; i++)
        for (var key in arguments[i])
          if (arguments[i].hasOwnProperty(key))
            arguments[0][key] = arguments[i][key];

      return arguments[0];
    },

    /*
    overridePolygonOnStop(e) {
      e.polygon.properties.name = "Area";
      e.polygon.properties.type = "Area";
    },
    */

    customPolygonMode() {
      const NewPolygonMode = this.extend(MapboxDraw.modes.draw_polygon, {
        onStop(e) {
          e.polygon.properties.name = "Area";
          e.polygon.properties.type = "Area";
        },
      });

      return NewPolygonMode;
    },

    /*
    overrideLineOnStop(e) {
      e.line.properties.name = "Measurement";
      e.line.properties.type = "Measurement";
    },
    */

    customLineMode() {
      const NewLineMode = this.extend(MapboxDraw.modes.draw_line_string, {
        onStop(e) {
          e.line.properties.name = "Measurement";
          e.line.properties.type = "Measurement";
        },
      });

      return NewLineMode;
    },

    customPointMode(vmDraw, objectType, objectName) {
      var CustomPointMode = {};

      CustomPointMode.onSetup = function (opts) {
        var state = {};
        state.type = objectType;
        state.name = objectName;
        state.vmDraw = vmDraw;
        return state;
      };

      CustomPointMode.onClick = function (state, e) {
        // Add a unique value to the query, to avoid 'duplicate navigation'
        // failure.  Must force new navigation, to get the location (in params)
        // through to the route

        let query = { ...state.vmDraw.$route.query };
        query.when = `${Date.now()}`;

        let routeName = "new-task";

        switch (state.type) {
          case "Incident":
            routeName = "new-incident";
            break;

          case "Comment":
            routeName = "new-task";
            break;

          case "Asset":
            routeName = "new-asset";
            break;
        }

        state.vmDraw.$router.push({
          name: routeName,
          params: { latLong: e.lngLat },
          query: query,
        });

        state.vmDraw.$emit("mode-complete");
        state.vmDraw.draw.changeMode("simple_select");
      };

      CustomPointMode.toDisplayFeatures = function (state, geojson, display) {
        display(geojson);
      };

      return CustomPointMode;
    },

    customModes() {
      var CustomModes = {};

      // customPointMode takes 'this' as a parameter so their embedded functions can
      // access the Vue world, including $router

      CustomModes.comment = this.customPointMode(this, "Comment", "Comment");
      CustomModes.asset = this.customPointMode(this, "Asset", "Asset");
      CustomModes.incident = this.customPointMode(this, "Incident", "Incident");
      CustomModes.draw_line_string = this.customLineMode();
      CustomModes.draw_polygon = this.customPolygonMode();

      return CustomModes;
    },

    init() {
      if (this.draw && this.loaded) {
        return this.draw;
      }

      let displayedControls = {
        point: false,
        line_string: false,
        polygon: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      };

      let customStyles = OperationsCustomStyle();

      let customModes = this.customModes();

      this.draw = new MapboxDraw({
        userProperties: true,
        controls: displayedControls,
        modes: Object.assign(customModes, MapboxDraw.modes),
        styles: customStyles,
      });

      this.map.addControl(this.draw, "top-right");

      this.loaded = true;

      return this.draw;
    },
  },
};
</script>

<style></style>
