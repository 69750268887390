<template>
  <div class="base-popup">
    <div class="point-option-menu content-menu">
      <div class="content-row">
        <button class="btn-plain" @click="editPoint">Edit</button>
      </div>
      <div class="content-row">
        <button class="btn-plain delete" @click="deletePoint">{{ deleteText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePopup",
  data() {
    return {
      confirmDelete: false
    }
  },
  computed: {
    deleteText() {
      return this.confirmDelete ? "Really Delete?" : "Delete"
    }
  },
  methods: {
    editPoint() {},
    deletePoint() {
      if (this.confirmDelete === false) {
        this.confirmDelete = true
      } else {
        this.$emit("confirmDelete")
      }
    }
  }
}
</script>

<style></style>
