<template>
  <div
    class="img-wrapper-relative control-wrapper"
    title="Traffic"
    @click="clickTraffic"
  >
    <img
      class="c-d-default-none img-traffic-under"
      alt="hide"
      src="@/assets/img/app/traffic-under-dark.svg"
    />
    <img
      class="c-d-dark-none img-traffic-under"
      alt="hide"
      src="@/assets/img/app/traffic-under.svg"
    />
    <img
      v-if="!trafficVisible"
      id="img-no-traffic-dark"
      class="c-d-default-none img-no-traffic"
      alt="hide"
      src="@/assets/img/app/traffic-no-dark.svg"
    />
    <img
      v-if="!trafficVisible"
      id="img-no-traffic-light"
      class="c-d-dark-none img-no-traffic"
      alt="hide"
      src="@/assets/img/app/traffic-no.svg"
    />
    <img
      v-if="trafficVisible"
      id="img-traffic-dark"
      class="c-d-default-none img-traffic"
      alt="hide"
      src="@/assets/img/app/traffic-dark.svg"
    />
    <img
      v-if="trafficVisible"
      id="img-traffic-light"
      class="c-d-dark-none img-traffic"
      alt="hide"
      src="@/assets/img/app/traffic-light.svg"
    />
  </div>
</template>

<script>
import { trafficLayers } from "@/utils/traffic-layers";
export default {
  name: "MapTrafficControl",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      trafficVisible: false,
    };
  },
  init() {
    this.loadTrafficSources();
  },
  methods: {
    loadTrafficSources() {
      var trafficLayer = this.map.addSource("trafficSource", {
        type: "vector",
        url: "mapbox://mapbox.mapbox-traffic-v1",
      });

      //this.map.setPaintProperty(trafficLayer, "raster-opacity", 1.0);
      //this.map.setPaintProperty(trafficLayer, "z-index", 1.0);
    },
    clickTraffic() {
      this.$emit("set-active-mode", "traffic");

      if (this.map.isStyleLoaded() == false) return;

      var trafficSource = this.map.getSource("trafficSource");

      if (typeof trafficSource == "undefined") this.loadTrafficSources();

      var firstPOILabel = this.map.getStyle().layers.filter(function (obj) {
        return obj["source-layer"] == "poi_label";
      });

      for (var i = 0; i < trafficLayers.length; i++) {
        var mapLayer = this.map.getLayer(trafficLayers[i].id);
        var exists = typeof mapLayer != "undefined";

        if (!this.trafficVisible) {
          if (exists) {
            this.map.setLayoutProperty(
              trafficLayers[i].id,
              "visibility",
              "visible"
            );
          } else {
            this.map.addLayer(trafficLayers[i], firstPOILabel[0].id);
          }
        } else {
          if (exists) {
            this.map.setLayoutProperty(
              trafficLayers[i].id,
              "visibility",
              "none"
            );
          }
        }
      }

      this.trafficVisible = !this.trafficVisible;

      this.$emit("set-active-mode", "none");
    },
  },
};
</script>

<style scoped>
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
</style>
