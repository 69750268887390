<template>
  <div class="bwx-popup__window d-none" v-if="popupVisible" id="popup-window">
    <div class="bwx-popup__frame">
      <div class="bwx-popup__title">
        <div class="bwx-popup__type" v-if="featureType != ''">
          {{ featureType }}
        </div>
        <div class="bwx-popup__action">
          <div
            class="btn btn-outline-info edit-button"
            @click.stop="handleEdit"
          >
            Edit
          </div>
        </div>
        <div class="bwx-popup__action">
          <div
            class="btn btn-outline-danger delete-button"
            @click.stop="handleDelete"
          >
            Delete
          </div>
        </div>
      </div>
      <div class="bwx-popup__name" v-if="featureName != ''">
        Name: {{ featureName }}
      </div>
      <div class="bwx-popup__desc" v-if="featureDescription != ''">
        Description: {{ featureDescription }}
      </div>
      <div class="bwx-popup__distance" v-if="featureDistance != ''">
        Length: {{ featureDistance }}
      </div>
      <div class="bwx-popup__area" v-if="featureArea != ''">
        Area: {{ featureArea }}
      </div>
      <div class="bwx-popup__perimeter" v-if="featurePerimeter != ''">
        Perimeter: {{ featurePerimeter }}
      </div>
    </div>
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
export default {
  name: "MapContextPopup",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contextMapFeature: null,
      contextPopup: null,
      popupVisible: false,
      featureName: "",
      featureType: "",
      featureDescription: "",
      featureDistance: "",
      featureArea: "",
      featurePerimeter: "",
    };
  },
  computed: {},
  watch: {
    popupVisible() {},
  },
  methods: {
    getCenter(feature) {
      var coords = [0, 0];
      var ne = [-180, -90];
      var sw = [180, 90];

      switch (feature.geometry.type) {
        case "Polygon":
          coords = feature.geometry.coordinates[0];
          break;

        case "LineString":
          coords = feature.geometry.coordinates;
          break;

        case "Point":
          coords = [feature.geometry.coordinates];
          break;
      }

      for (var i = 0; i < coords.length; i++) {
        // ne is the maximum longitude and latitude
        ne[0] = coords[i][0] > ne[0] ? coords[i][0] : ne[0];
        ne[1] = coords[i][1] > ne[1] ? coords[i][1] : ne[1];

        // sw is the minimum longitude and latitude
        sw[0] = coords[i][0] < sw[0] ? coords[i][0] : sw[0];
        sw[1] = coords[i][1] < sw[1] ? coords[i][1] : sw[1];
      }
      var llb = new mapboxgl.LngLatBounds(sw, ne);
      return llb.getCenter();
    },

    calcMeasurements(feature) {
      var featureMeasurement = {};

      if (feature == null) return;

      if (feature.geometry == null) return;

      if (feature.geometry.type == "LineString") {
        const line = turf.lineString(feature.geometry.coordinates);

        const distance = turf.length(line, { units: "kilometers" });
        this.featureDistance = Math.round(distance * 1000) + " m";
      }

      if (feature.geometry.type == "Polygon") {
        const line = turf.lineString(feature.geometry.coordinates[0]);

        const perimeter = turf.length(line, { units: "kilometers" });
        this.featurePerimeter = Math.round(perimeter * 1000) + " m";

        const poly = turf.polygon(feature.geometry.coordinates);

        const area = turf.area(poly);
        this.featureArea = Math.round(area) + "  sq m";
      }

      return featureMeasurement;
    },

    buildTechnicalPopup(drawFeature) {
      this.featureName = "";
      this.featureType = drawFeature.geometry.type;
      this.featureDescription = "";
      this.featureDistance = "";
      this.featureArea = "";
      this.featurePerimeter = "";

      this.calcMeasurements(drawFeature);

      const hasName =
        Object.prototype.hasOwnProperty.call(drawFeature.properties, "name") &&
        drawFeature.properties.name != "";

      switch (drawFeature.geometry.type) {
        case "Polygon":
          this.featureType = "Area Measurement";
          break;

        case "LineString":
          this.featureType = "Distance Measurement";
          break;

        case "Point":
          switch (drawFeature.properties.customType) {
            case "tasks":
              this.featureType = "Note";
              if (hasName) this.featureName = drawFeature.properties.name;
              break;

            case "incidents":
              this.featureType = "Incident";
              if (hasName) this.featureName = drawFeature.properties.name;
              break;

            case "asset":
              this.featureType = "Asset";
              if (hasName) this.featureName = drawFeature.properties.name;
              break;
          }
          break;
      }

      if (
        Object.prototype.hasOwnProperty.call(
          drawFeature.properties,
          "description"
        ) &&
        drawFeature.properties.description != ""
      )
        this.featureDescription = drawFeature.properties.description;
    },

    positionPopup(popupWindow, point) {
      popupWindow.style.left = point.x;
      popupWindow.style.top = point.y;
    },

    showPopup(feature, lngLat) {
      var featureId = feature.properties.id;
      var drawFeature = this.draw.get(featureId);

      var coordinates = lngLat;

      if (drawFeature.geometry.type == "Point")
        coordinates = this.getCenter(drawFeature);

      var point = this.map.project(coordinates);
      point.y -= 15;
      coordinates = this.map.unproject(point);

      this.buildTechnicalPopup(drawFeature);

      this.popupVisible = true;

      setTimeout(() => {
        let popupWindow = document.getElementById("popup-window");

        if (popupWindow != null) {
          this.positionPopup(popupWindow, point);
          popupWindow.classList.remove("d-none");
        }
      }, 20);
    },

    hidePopup() {
      if (this.popupVisible == false) return;

      let popupWindow = document.getElementById("popup-window");
      popupWindow.classList.add("d-none");

      this.popupVisible = false;
    },

    setHoverState(feature, hoverState, lngLat) {
      if (typeof feature.source == "undefined") return;

      if (typeof feature.properties == "undefined") return;

      if (typeof feature.properties.id == "undefined") return;

      this.map.setFeatureState(
        { source: feature.source, id: feature.properties.id },
        { hover: hoverState }
      );

      if (this.contextPopup != feature) {
        this.hidePopup();

        if (hoverState) this.showPopup(feature, lngLat);
      }
    },

    sameFeature(feature1, feature2) {
      if (feature1 == null && feature2 == null) return true;

      if (feature1 == null || feature2 == null) return false;

      return feature1.properties.id == feature2.properties.id;
    },

    findFeatureUnderMouse(e) {
      if (this.draw == null || typeof this.draw == "undefined") return null;

      const mode = this.draw.getMode();

      if (mode != "simple_select" && mode != "direct_select") return null;

      if (typeof e == "undefined") return null;

      if (typeof e.featureTarget == "undefined") return null;

      return e.featureTarget;
    },

    mapMouseMove(e) {
      var feature = this.findFeatureUnderMouse(e);

      if (this.sameFeature(feature, this.contextMapFeature)) return;

      if (this.contextMapFeature) {
        this.setHoverState(this.contextMapFeature, false, null);

        this.contextMapFeature = null;
      }

      if (feature == null) return;

      this.contextMapFeature = feature;

      this.setHoverState(this.contextMapFeature, true, e.lngLat);
    },

    touchPopup(e) {
      if (e.type != "touchstart") return false;

      this.hidePopup();

      if (
        !e.hasOwnProperty("featureTarget") ||
        typeof e.featureTarget == "undefined"
      )
        return false;

      this.contextMapFeature = e.featureTarget;

      this.setHoverState(this.contextMapFeature, true, e.lngLat);
      return true;
    },

    handleEdit() {
      this.$emit("edit-point", this.contextMapFeature);
    },

    handleDelete() {
      this.$emit("delete-point", this.contextMapFeature);
    },
  },
};
</script>
<style lang="scss" scoped>
.bwx-popup {
  &__window {
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 100;
  }
  &__frame {
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    background-color: hsla(180, 100%, 100%, 1);
  }
  &__title {
    width: 100%;
    border-bottom: 1px solid hsla(180, 0%, 80%, 1);
    background-color: hsla(180, 0%, 90%, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__type {
    font-weight: 700;
    font-size: 14pt;
    padding: 8px;
    flex-grow: 1;
  }
  &__action {
    flex-grow: 0;
    margin: 4px;
  }
  &__name {
    font-weight: 400;
    font-size: 14pt;
    padding: 8px;
  }
  &__desc {
    font-weight: 400;
    font-size: 14pt;
    padding: 8px;
  }
  &__distance {
    font-weight: 400;
    font-size: 14pt;
    padding: 8px;
  }
  &__area {
    font-weight: 400;
    font-size: 14pt;
    padding: 8px;
  }
  &__perimeter {
    font-weight: 400;
    font-size: 14pt;
    padding: 8px;
  }
}
.edit-button,
.delete-button {
  padding: 0px 4px;
}
</style>
<style>
/*
div.bwx-popup-frame {
  border: 1px solid hsla(180, 0%, 80%, 1);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12pt;
  line-height: 1.2;
  padding: 0px;
  background-color: #ffffff;
}
*/
</style>
