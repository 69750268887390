<template>
  <div class="side-bar-controls-override-controls">
    <div id="map-panel" class="map-panel bg-light">
      <div id="toggle-panel" title="Toggle Controls" @click="toggleControls">
        <!--
        <img
          id="map-panelButton-dark"
          class="c-d-default-none img-accordion"
          alt="hide"
          src="@/assets/img/nav/arrow-down-dark.svg"
        />
        <img
          id="map-panelButton-light"
          class="c-d-dark-none img-accordion"
          alt="hide"
          src="@/assets/img/nav/arrow-down-light.svg"
        />
        -->
      </div>
      <div
        id="map-controls"
        class="map-controls"
        :class="{ 'hide-controls': !showControls }"
      >
        <!--
        <div>
          <hr class="c-d-default-none controls-break-dark" />
          <hr class="c-d-dark-none controls-break-light" />
        </div>
        -->
        <map-geolocate-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          :geolocate="geolocate"
        ></map-geolocate-control>
        <map-center-map-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
        ></map-center-map-control>
        <div>
          <hr class="c-d-default-none controls-break-dark" />
          <hr class="c-d-dark-none controls-break-light" />
        </div>
        <div class="traffic-weather-area">
          <map-traffic-control
            @set-active-mode="setActiveMode"
            :map="map"
            :draw="draw"
          ></map-traffic-control>
          <map-weather-control
            @set-active-mode="setActiveMode"
            :map="map"
            :draw="draw"
          ></map-weather-control>
        </div>
        <div>
          <hr class="c-d-default-none controls-break-dark" />
          <hr class="c-d-dark-none controls-break-light" />
        </div>
        <map-distance-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          @set-draw-mode="setDrawMode"
          @clear-draw-mode="clearDrawMode"
        ></map-distance-control>
        <map-area-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          @set-draw-mode="setDrawMode"
          @clear-draw-mode="clearDrawMode"
        ></map-area-control>
        <map-asset-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          @set-draw-mode="setDrawMode"
          @clear-draw-mode="clearDrawMode"
        ></map-asset-control>
        <map-task-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          @set-draw-mode="setDrawMode"
          @clear-draw-mode="clearDrawMode"
        ></map-task-control>
        <map-incident-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          @set-draw-mode="setDrawMode"
          @clear-draw-mode="clearDrawMode"
        ></map-incident-control>
        <map-trash-control
          @set-active-mode="setActiveMode"
          :map="map"
          :draw="draw"
          :selectedFeature="selectedFeature"
          @delete-point="deletePoint"
        ></map-trash-control>
      </div>
    </div>
  </div>
</template>
<script>
import MapDistanceControl from "./MapDistanceControl";
import MapAreaControl from "./MapAreaControl";
import MapGeolocateControl from "./MapGeolocateControl";
import MapCenterMapControl from "./MapCenterMapControl";
import MapTrafficControl from "./MapTrafficControl";
import MapWeatherControl from "./MapWeatherControl";
import MapAssetControl from "./MapAssetControl";
import MapTaskControl from "./MapTaskControl";
import MapIncidentControl from "./MapIncidentControl";
import MapTrashControl from "./MapTrashControl";
export default {
  name: "MapControlsDrawer",
  components: {
    MapDistanceControl,
    MapAreaControl,
    MapGeolocateControl,
    MapCenterMapControl,
    MapTrafficControl,
    MapWeatherControl,
    MapAssetControl,
    MapTaskControl,
    MapIncidentControl,
    MapTrashControl,
  },
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
    geolocate: {
      type: Object,
      default: null,
    },
    selectedFeature: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showControls: true,
      activeMode: "none",
      savedMode: "",
    };
  },
  watch: {
    activeMode: function () {
      this.consoleModes("Active Mode changed");
    },
    savedMode: function () {
      this.consoleModes("Saved Mode changed");
    },
  },
  methods: {
    consoleModes(event) {
      console.log(
        `${event}.  Draw: "${this.draw.getMode()}".  Saved: "${
          this.savedMode
        }".  Active: "${this.activeMode}".`
      );
    },
    toggleControls() {
      this.showControls = !this.showControls;
    },

    setActiveMode(mode) {
      this.activeMode = mode;
    },

    checkActiveMode(mode) {
      return this.activeMode === mode;
    },

    getActiveMode() {
      return this.activeMode;
    },

    setDrawMode(mode) {
      if (mode == "") return;

      this.savedMode = mode;
    },

    clearDrawMode() {
      if (this.savedMode == "") return;

      this.savedMode = "";
    },

    deletePoint(selectedFeature) {
      this.$emit("delete-point", selectedFeature);
    },
  },
};
</script>
<style lang="scss">
div.mapboxgl-popup-content {
  padding: 0px;
}

div.mapboxgl-popup-content-wrapper {
  border: 1px solid black;
}

button.mapboxgl-popup-close-button {
  font-size: 18pt;
  padding: 8px;
  padding-top: 4px;
  outline: 0px;
}
</style>

<style lang="scss" scoped>
.map-controls {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: unset;
  will-change: max-height, opacity;
}

.hide-controls {
  transition: all 0.3s ease;
  // opacity: 0;
  max-height: 0;
  display: none;
  max-height: 0px;
}

#map-controls {
  width: 100%;
  margin: auto;
  padding-bottom: 4px;
}
.img-accordion {
  width: 46px;
  height: 46px;
  opacity: 1;
  z-index: 100;
  padding: 6px 2px;
}
.img-traffic,
.img-weather,
.img-lightning {
  opacity: 0.95;
  width: 46px;
  height: 46px;
  /* padding: 6px 2px; */
}
#img-no-traffic,
#img-no-weather,
#img-no-lightning {
  opacity: 1;
  position: absolute;
  top: 0px;
  width: 46px;
  height: 46px;
}
.img-traffic-under,
.img-weather-under {
  opacity: 1;
  position: absolute;
  top: 0px;
  width: 46px;
  height: 46px;
}
.map-panel {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  width: auto;
  z-index: 2;
  border: 0px solid #797979;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.c-dark-theme .map-panel {
  border: 0px solid #f9f9f9;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
}
.side-bar-controls-override-controls {
  position: absolute;
  top: 76px;
  left: 10px;
}
#map-panel img,
#map-panel svg,
#fitbounds-panel svg,
#fitbounds-panel img {
  cursor: pointer;
}
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
.controls-break-light {
  color: #797979;
  margin: 0px;
}
.controls-break-dark {
  color: #f9f9f9;
  margin: 0px;
}

.traffic-weather-area {
  background-color: rgba(130, 130, 130, 0.221);
}
</style>
