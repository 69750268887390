<template>
  <div class="map-wrapper">
    <div v-if="!mapData">no data</div>
    <map-display
      v-if="!loading && mapData"
      :mapData="mapData"
      :mapFeatures="combinedMapFeatures"
    />
    <router-view />
  </div>
</template>
<script>
import MapDisplay from "@/components/map/MapDisplay";
import { getMapId } from "@/utils/MapTools";
import { mapGetters, mapState } from "vuex";
export default {
  name: "MainMap",
  components: {
    MapDisplay,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("map", ["mapboxData", "combinedMapFeatures"]),
    ...mapState("map", ["mapData", "mapFeatures"]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let loader = this.$loading.show({
        container: this.$refs.page,
      });
      let mapId = getMapId();
      if (mapId) {
        Promise.all([
          this.$store.dispatch("map/loadMapData", mapId),
          this.$store.dispatch("map/loadMapFeatures", mapId),
        ]).then((values) => {
          loader.hide();
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.map-wrapper {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
}

h3 {
  margin-bottom: 0px;
}
</style>
