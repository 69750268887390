<template>
  <div class="row view-panel">
    <div class="cad-wrapper" @click="clickCad">
      <img
        id="img-cad-map-dark"
        class="c-d-default-none img-cad-map"
        alt="CAD"
        src="@/assets/img/layer/cad-map-dark.svg#cad-map"
      />
      <img
        id="img-cad-map-light"
        class="c-d-dark-none img-cad-map"
        alt="CAD"
        src="@/assets/img/layer/cad-map-light.svg#cad-map"
      />
      <div
        class="img-cad-selected active-selection"
        :class="{ hide: !checkActive('cad') }"
      >
        <img
          class="c-d-dark-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-light.svg"
        />
        <img
          class="c-d-default-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-dark.svg"
        />
      </div>
      <img
        class="c-d-dark-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-light.svg"
      />
      <img
        class="c-d-default-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-dark.svg"
      />
    </div>
    <div class="satellite-wrapper" @click="clickSatellite">
      <img
        id="img-satellite-dark"
        class="c-d-default-none img-satellite"
        alt="Satellite"
        src="@/assets/img/layer/satellite-dark.svg#satellite"
      />
      <img
        id="img-satellite"
        class="c-d-dark-none img-satellite"
        alt="Satellite"
        src="@/assets/img/layer/satellite-light.svg#satellite"
      />
      <div
        class="img-satellite-selected active-selection"
        :class="{ hide: !checkActive('satellite') }"
      >
        <img
          class="c-d-dark-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-light.svg"
        />
        <img
          class="c-d-default-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-dark.svg"
        />
      </div>
      <img
        class="c-d-dark-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-light.svg"
      />
      <img
        class="c-d-default-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-dark.svg"
      />
    </div>
    <div class="drone-wrapper" @click="clickDrone">
      <img
        id="img-drone"
        class="c-d-default-none img-drone"
        alt="Drone"
        src="@/assets/img/layer/drone-dark.svg#drone"
      />
      <img
        id="img-drone"
        class="c-d-dark-none img-drone"
        alt="Drone"
        src="@/assets/img/layer/drone-light.svg#drone"
      />
      <div
        class="img-drone-selected active-selection"
        :class="{ hide: !checkActive('drone') }"
      >
        <img
          class="c-d-dark-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-light.svg"
        />
        <img
          class="c-d-default-none img-view-selected"
          alt="not-selected"
          src="@/assets/img/layer/highlight-layer-dark.svg"
        />
      </div>
      <img
        class="c-d-dark-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-light.svg"
      />
      <img
        class="c-d-default-none img-under"
        alt="not-selected"
        src="@/assets/img/layer/under-dark.svg"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "MapLayerControls",
  props: {
    map: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      active: "cad",
    };
  },
  methods: {
    clickLayerView(selectedView) {
      var viewLayers = [
        {
          name: "satellite",
          id: "Satellite",
        },
        {
          name: "drone",
          id: "Drone Scan",
        },
        {
          name: "cad",
          id: "CAD Map",
        },
        {
          name: "streets",
          id: "<no layer>",
        },
      ];

      // This code implements 'radio button' behavior

      let map = this.map;

      viewLayers.forEach((item, index) => {
        var mapLayer = map.getLayer(item.id);
        var exists = typeof mapLayer != "undefined";

        if (exists) {
          if (selectedView == item.id) {
            if (mapLayer.visibility === "none") {
              map.setLayoutProperty(item.id, "visibility", "visible");
            }
          } else {
            if (mapLayer.visibility !== "none") {
              map.setLayoutProperty(item.id, "visibility", "none");
            }
          }
        }
      });
    },

    clickDrone() {
      this.setActive("drone");
      this.clickLayerView("Drone Scan");
    },

    clickCad() {
      this.setActive("cad");
      this.clickLayerView("CAD Map");
    },

    clickSatellite() {
      this.setActive("satellite");
      this.clickLayerView("Satellite");
    },
    setActive(name) {
      this.active = name;
    },
    checkActive(name) {
      return this.active === name;
    },
  },
};
</script>
<style lang="scss" scoped>
.active-selection {
  opacity: 1;
}
.hide {
  transition: opacity 0.3s ease;
  opacity: 0;
}

.view-panel {
  top: 10px;
  height: auto;
  left: 10px;
  position: absolute;
  width: auto;
  z-index: 2;
  margin: 0px;
}

.img-under {
  position: absolute;
  display: block;
  width: 54px;
  height: 54px;
  z-index: 1000;
}
.img-view-selected {
  position: absolute;
  display: block;
  width: 54px;
  height: 54px;
  z-index: 2000;
}
.img-satellite,
.img-drone,
.img-cad-map {
  position: absolute;
  display: block;
  width: 54px;
  height: 54px;
  z-index: 3000;
}
.cad-wrapper,
.satellite-wrapper,
.drone-wrapper {
  border: 0px solid #797979;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: relative;
  margin-right: 6px;
  width: 54px;
  height: 54px;
  cursor: pointer;
}

.c-dark-theme .cad-map-wrapper,
.c-dark-theme .satellite-wrapper,
.c-dark-theme .drone-wrapper {
  border: 0px solid #f9f9f9;
  box-shadow: 0 0.2re m 0.5rem rgba(0, 0, 0, 0.5);
}
</style>
