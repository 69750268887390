<template>
  <div
    class="img-wrapper-relative control-wrapper"
    title="Trash"
    @click="clickTrash"
  >
    <img
      v-if="this.$parent.checkActiveMode('trash')"
      class="
        c-d-default-none
        img-draw_line_string
        img-hidden
        img-draw_line_string-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight-dark.svg"
    />
    <!-- highlight -->
    <img
      v-if="this.$parent.checkActiveMode('trash')"
      class="
        c-d-dark-none
        img-draw_line_string
        img-hidden
        img-draw_line_string-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight-light.svg"
    />
    <img
      id="img-trash-dark"
      class="c-d-dark-none img-trash"
      alt="Trash Can Icon"
      src="@/assets/img/app/trash-light.svg"
    />
    <img
      id="img-trash-light"
      class="c-d-default-none img-trash"
      alt="Trash Can Icon"
      src="@/assets/img/app/trash-dark.svg"
    />
  </div>
</template>

<script>
export default {
  name: "MapTrashControl",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
    selectedFeature: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickTrash() {
      if (this.selectedFeature) {
        this.$emit("delete-point", this.selectedFeature);
      }
    },
  },
};
</script>

<style scoped>
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
</style>
