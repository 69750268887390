<template>
  <div
    class="img-wrapper-relative control-wrapper"
    title="Weather"
    @click="clickWeather"
  >
    <img
      class="c-d-default-none img-traffic-under"
      alt="hide"
      src="@/assets/img/app/weather/weather-under-dark.svg"
    />
    <img
      class="c-d-dark-none img-traffic-under"
      alt="hide"
      src="@/assets/img/app/weather/weather-under.svg"
    />
    <img
      v-if="!weatherVisible"
      id="img-no-weather-dark"
      class="c-d-default-none img-no-weather"
      alt="hide"
      src="@/assets/img/app/weather/weather-no-dark.svg"
    />
    <img
      v-if="!weatherVisible"
      id="img-no-weather-light"
      class="c-d-dark-none img-no-weather"
      alt="hide"
      src="@/assets/img/app/weather/weather-no.svg"
    />
    <img
      v-if="weatherVisible"
      id="img-weather-dark"
      class="c-d-default-none img-weather"
      alt="hide"
      src="@/assets/img/app/weather/weather-dark.svg"
    />
    <img
      v-if="weatherVisible"
      id="img-weather-light"
      class="c-d-dark-none img-weather"
      alt="hide"
      src="@/assets/img/app/weather/weather-light.svg"
    />
  </div>
</template>

<script>
export default {
  name: "MapWeatherControl",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      weatherVisible: false,
    };
  },
  init() {
    this.loadWeatherSources();
  },
  methods: {
    loadWeatherSources() {
      this.map.addSource("aerisweather-temperatures", {
        type: "raster",
        tiles: [
          "https://maps1.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/temperatures/{z}/{x}/{y}/current.png",
          "https://maps2.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/temperatures/{z}/{x}/{y}/current.png",
          "https://maps3.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/temperatures/{z}/{x}/{y}/current.png",
          "https://maps4.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/temperatures/{z}/{x}/{y}/current.png",
        ],
        tileSize: 64,
        attribution: "<a href='https://www.aerisweather.com/'>AerisWeather</a>",
      });

      this.map.addLayer({
        id: "aerisweather-temperatures-layer",
        type: "raster",
        source: "aerisweather-temperatures",
        minzoom: 0,
        maxzoom: 22,
      });

      /*
      this.map.addSource("aerisweather-features", {
        type: "raster",
        tiles: [
          "https://maps.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/lightning-strikes-icons,wind-speeds,wind-dir/{z}/{x}/{y}/current.png",
          "https://maps.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/lightning-strikes-15m-icons,fires-obs-points/{z}/{x}/{y}/-10.png",
          "https://maps.aerisapi.com/2QDeVWFu7NPLDYQHnjOph_J2rWDS1sSvxtr3zsUl5bgja335VKdIi7l6IvVkct/lightning-strikes-15m-icons,fires-obs-points/{z}/{x}/{y}/-60.png",
        ],

        tileSize: 64,
        attribution: "<a href='https://www.aerisweather.com/'>AerisWeather</a>",
      });

      this.map.addLayer({
        id: "aerisweather-features-layer",
        type: "raster",
        source: "aerisweather-features",
        minzoom: 0,
        maxzoom: 22,
      });
      */

      // trafficLayer.setZIndex(6000 );
      // weatherLayer.setZIndex(2900 );
    },
    clickWeather() {
      this.$emit("set-active-mode", "weather");

      if (this.map.isStyleLoaded() == false) return;

      var weatherSource = this.map.getSource("aerisweather-temperatures");

      if (typeof weatherSource == "undefined") this.loadWeatherSources();

      var featuresLayer = "aerisweather-features-layer";
      var temperaturesLayer = "aerisweather-temperatures-layer";

      var featuresLayerExists = this.map.getLayer(featuresLayer);
      var temperaturesLayerExists = this.map.getLayer(temperaturesLayer);

      if (!this.weatherVisible) {
        if (featuresLayerExists) {
          this.map.setLayoutProperty(featuresLayer, "visibility", "visible");

          this.map.setPaintProperty(featuresLayer, "raster-opacity", 0.1);
        }

        if (temperaturesLayerExists) {
          this.map.setLayoutProperty(
            temperaturesLayer,
            "visibility",
            "visible"
          );

          this.map.setPaintProperty(temperaturesLayer, "raster-opacity", 0.1);
        }
      } else {
        if (featuresLayerExists)
          this.map.setLayoutProperty(featuresLayer, "visibility", "none");

        if (temperaturesLayerExists)
          this.map.setLayoutProperty(temperaturesLayer, "visibility", "none");
      }

      if (!featuresLayerExists && !temperaturesLayerExists) {
        alert(
          "sorry, weather layers are not available in this version of skyfall."
        );
      }
      this.weatherVisible = !this.weatherVisible;

      this.$emit("set-active-mode", "none");
    },
  },
};
</script>

<style scoped>
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
</style>
