module.exports = {
    entityList: ["incident", "task", "asset"],

    setEntityParam(key, value) {
        // clears the query params of all entity-parameters
        // ensures only one entity Param is present
        var queryParams = this.$route.query
        for (var entity of entityList) {
            delete queryParams[entity]
        }
        this.$router.push({ path: '/', query: { ...queryParams, [key]: value } })
    },

    pluralize(entityType) {

        let isPlural = entityType.substring(entityType.length - 1).toLowerCase() == 's';

        if (isPlural)
            return (entityType);

        switch (entityType) {

            case 'emergency':
                return ('emergencies');
        }

        return (entityType + 's');
    },

    singularize(entityType) {

        let isPlural = entityType.substring(entityType.length - 1).toLowerCase() == 's';

        if (!isPlural)
            return (entityType);

        switch (entityType) {

            case 'emergencies':
                return ('emergency');
        }

        return (entityType.substring(0, entityType.length - 1));
    },

    capitalize(word) {

        if (!word || word.length == 0)
            return (word);

        return (word[0].toUpperCase() + word.substring(1));
    },
}